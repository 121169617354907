import { connect } from 'react-redux';

import FamilyCategory from './familyCategory';
import { getProductsStatus, getProductsData, getIsStockOrder } from '../../store/selectors';
import { loadProducts } from '../../store/action-creators';

const mapStateToProps = (state) => ({
  productsStatus: getProductsStatus(state),
  productsData: getProductsData(state),
  isStockOrder: getIsStockOrder(state), 
});

const mapDispatchToProps = {
  loadProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyCategory);