import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { find } from 'lodash';
import ProductCard from '../../components/productCard/productCard';
import { Container, Row, Col } from "react-bootstrap";
import { OrderStatus } from '../../store/constants';
import(/* webpackChunkName: "family-category" */'./familyCategory.scss');

const FamilyCategory = ({
  loadProducts,
  productsData,
  productsStatus,
  isStockOrder,
}) => {
  const [productFamily, setProductFamily] = useState();
  const [familyName, setFamilyName] = useState();
  const { productId } = useParams();

  useEffect(() => {
    // console.log("productsData 1", productsData)

    if (productsData) {
      let temporaryProductFamily = find(productsData, { 'Code': productId })
      setProductFamily(temporaryProductFamily);
      setFamilyName(temporaryProductFamily.Name);
      temporaryProductFamily = temporaryProductFamily.InverseParentCodeNavigation
      setProductFamily(temporaryProductFamily);
    }
  }, [productId, productsData, productsStatus]);

  useEffect(() => {
    if (productId == "StockOrders") {
      if (loadProducts) {
        // console.log("StockOrders", productId)
        // console.log("productsData", productsData)
        loadProducts("StockOrders", productsData);
      }
    }
  }, [productId, loadProducts]);

  return (
    <div className="family-category-main-div">
      <div className="family-category-content">
        {isStockOrder == OrderStatus.STOCK_ORDER &&
          <div className="alert alert-danger" role="alert">
            <p>You cannot add items from this section because you have an ongoing stock order. Please clear your cart before proceeding.</p>
          </div>
        }
        <h1>{familyName}</h1>
      </div>
      {productFamily &&
        <Row className="family-category-cards">
          {productFamily.map((product, index) => (
            <ProductCard product={product} key={index} index={index} isStockOrder={isStockOrder} />
          ))}
        </Row>
      }
    </div>
  )
};

FamilyCategory.propTypes = {
  loadProducts: PropTypes.func,
  loadFamilyCategory: PropTypes.func,
  productsData: PropTypes.array,
}

export default FamilyCategory;
