import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { filter, uniqBy, findIndex, find } from "lodash";
import InsertLink from "@material-ui/icons/InsertLink";
import LinkOff from "@material-ui/icons/LinkOff";
import(
  /* webpackChunkName: "order-builder-ear-pieces-lengths" */ "./earPiecesLengths.scss"
);

import testModel from "../../../../assets/img/ModelTest.png";

const EarPiecesLengths = ({
  setEarPiecesReceiversForOrder,
  lengthsLeft,
  lengthsRight,
  activeRightEarPiecesReceiver,
  activeLeftEarPiecesReceiver,
  fullListOfEarPiecesReceiversLeft,
  fullListOfEarPiecesReceiversRight,
  leftSelected,
  rightSelected,
  iteModelLinked,
  selectedModel
}) => {
  const [activeRightReceiver, setActiveRightEarPiecesLengths] = useState();
  const [activeLeftReceiver, setActiveLeftEarPiecesLengths] = useState();
  const [activeRightLength, setActiveRightLength] = useState();
  const [activeLeftLength, setActiveLeftLength] = useState();
  const [linked, setLinked] = useState(true);

  useEffect(() => {
    // console.log("TESTING??");
    // console.log("SETTING LEGNTHS LEFT -", lengthsLeft)
    // console.log("SETTING LEGNTHS RIGHT -", lengthsRight)
    // If list of lengths avaliable is updated, set the first length in the array to be the active length
    if(lengthsLeft) {
      setActiveLeftLength(lengthsLeft[0].Code);
    }

    if(lengthsRight) {
      setActiveRightLength(lengthsRight[0].Code);
    }
  }, [lengthsLeft, lengthsRight, selectedModel]);

  useEffect(() => {
    // This section collects which length has been selected and finds the matching reciever to set it for the order.
    let receiversForOrder = {
      left: {},
      right: {},
    };
    if(activeRightEarPiecesReceiver && activeRightLength){
      let tempRightReceivers = [...fullListOfEarPiecesReceiversRight];
      const tempRightReceiversAfterPL = filter(tempRightReceivers, {"PerformanceLevel": activeRightEarPiecesReceiver}, []);
      const lengthSizeRight = activeLeftLength.charAt(activeLeftLength.length - 1);
      const tempRightReceiversAfterMT = filter(tempRightReceiversAfterPL, {"ModelType": lengthSizeRight}, []);
      const tempRightReceiverAfterFind = find(tempRightReceiversAfterMT, function(o) { return o.Name.charAt(o.Name.length - 1) === "R" });
      receiversForOrder.right = tempRightReceiverAfterFind;
    }
    if(activeLeftEarPiecesReceiver && activeLeftLength) {
      let tempLeftReceivers = [...fullListOfEarPiecesReceiversLeft];
      const tempLeftReceiversAfterPL = filter(tempLeftReceivers, {"PerformanceLevel": activeLeftEarPiecesReceiver}, []);
      const lengthSizeLeft = activeLeftLength.charAt(activeLeftLength.length - 1);
      const tempLeftReceiversAfterMT = filter(tempLeftReceiversAfterPL, {"ModelType": lengthSizeLeft}, []);
      const tempLeftReceiverAfterFind = find(tempLeftReceiversAfterMT, function(o) { return o.Name.charAt(o.Name.length - 1) === "L" });
      receiversForOrder.left = tempLeftReceiverAfterFind;
    }
    setEarPiecesReceiversForOrder(receiversForOrder);
  }, [activeRightLength, activeLeftLength, activeRightEarPiecesReceiver, activeLeftEarPiecesReceiver]);

  return (
    <div>
      {(lengthsLeft || lengthsRight) && (leftSelected || rightSelected) && (
        <div className="earPiecesLengths-component-main-div order-builder-component">
          <Row className="order-builder-component-title">
            <Col xs={4}>
              <h2>Choose a length</h2>
            </Col>
            <Col xs={8}>
              <Row className="pt-4">
                  <Col md={5}>
                    {rightSelected && (
                      <div className="text-right">
                        <h3>Right Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    {rightSelected && leftSelected && (
                      <div className="text-center link-div">
                        {linked && iteModelLinked ? (
                          <InsertLink
                            className="linked"
                            onClick={() => setLinked(false)}
                          />
                        ) : (
                          <LinkOff
                            onClick={() => setLinked(!linked)}
                            className="unlinked"
                          />
                        )}
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    {leftSelected && (
                      <div>
                        <h3>Left Ear</h3>
                      </div>
                    )}
                  </Col>
                <Col md={6}>
                  <Row className="earPiecesLengths-bottom-left-row earPiecesLengths-bottom-row">
                    {lengthsRight &&
                      rightSelected &&
                      lengthsRight.map((length, index) => (
                        <Col key={index} md={6} lg={3}>
                          <a
                            onClick={() => {
                              setActiveRightLength(length.Code);
                              if(linked && iteModelLinked) {
                                setActiveLeftLength(length.Code);
                              }
                            }}
                          >
                            <div
                              className={`earPiecesLengths-box ${
                                activeRightLength === length.Code
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="earPiecesLengths-box-content">
                                <div className="earPiecesLengths-box-text">
                                  <p>{length.Name}</p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Col>
                      ))}
                  </Row>
                </Col>
                <Col md={6} className="left-side">
                  <Row className="earPiecesLengths-bottom-right-row earPiecesLengths-bottom-row">
                    {lengthsLeft &&
                      leftSelected &&
                      lengthsLeft.map((length, index) => (
                        <Col key={index} md={6} lg={3}>
                          <a
                            onClick={() => {
                              setActiveLeftLength(length.Code);
                              if(linked && iteModelLinked) {
                                setActiveRightLength(length.Code);
                              }
                            }}
                          >
                            <div
                              // For active, check if length.Code is in array.
                              className={`earPiecesLengths-box ${
                                activeLeftLength === length.Code
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="earPiecesLengths-box-content">
                                <div className="earPiecesLengths-box-text">
                                  <p>{length.Name}</p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default EarPiecesLengths;
