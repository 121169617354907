import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';

import store from './store';
import AppComponent from './app.js?v=2';

moment.tz.setDefault('Pacific/Auckland');

moment.updateLocale('en-nz', {
  week: {
    dow: 0,
  },
});

moment.locale('en-nz');

// Version checking for cache busting
const currentVersion = '1.0.2'; // Update this version with each deployment
const cachedVersion = localStorage.getItem('appVersion');

if (currentVersion !== cachedVersion) {
  // Update the version in localStorage
  localStorage.setItem('appVersion', currentVersion);
  
  // Force reload to clear cache and load new content
  window.location.reload(true); 
} else {
  // If version is up-to-date, render the application
  ReactDOM.render(
    <Provider store={store}>
      <AppComponent />
    </Provider>,
    document.getElementById('root')
  );
}