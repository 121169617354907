import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { find, size, remove } from "lodash";
import(
  /* webpackChunkName: "order-builder-ite-custom-options" */ "./iteCustomOptions.scss"
);
import InsertLink from "@material-ui/icons/InsertLink";
import LinkOff from "@material-ui/icons/LinkOff";
import testModel from "../../../../assets/img/ModelTest.png";
import config from "../../../../config";

const ITECustomOptions = ({
  customOptionsGroupLeft,
  customOptionsGroupRight,
  setITECustomOptionsForOrder,
  leftSelected,
  rightSelected,
  iteModelLinked,
  manualVent
}) => {
  const [linked, setLinked] = useState(true);
  const [selectedCustomOptionsRight, setSelectedCustomOptionsRight] = useState([]);
  const [selectedCustomOptionsLeft, setSelectedCustomOptionsLeft] = useState([]);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [hasAdvancedSettings, setHasAdvancedSettings] = useState(false);
  var manualVentOptionToRemove = "AOV Acoustically Optimized Venting";
  if(config.siteName == "UNITRON") {
    manualVentOptionToRemove = "IntelliVent";
  }
  useEffect(() => {
    // console.log("Should update", customOptionsGroupRight, customOptionsGroupLeft)
  }, [customOptionsGroupRight, customOptionsGroupLeft])

  useEffect(() => {
    // Set default values for each option
    const hasAdvancedSettingsRight = find(customOptionsGroupRight, { IsAdvanced: true });
    const hasAdvancedSettingsLeft = find(customOptionsGroupLeft, { IsAdvanced: true });
    //console.log(hasAdvancedSettingsLeft, hasAdvancedSettingsRight)
    if (hasAdvancedSettingsRight || hasAdvancedSettingsLeft) {
      setHasAdvancedSettings(true);
    }
    else {
      setHasAdvancedSettings(false);
    }

    if (customOptionsGroupRight) {
      let tempOptionsArrayRight = [];
      customOptionsGroupRight.forEach((option) => {
        if(manualVent && option.DefaultValue == manualVentOptionToRemove) {
          var optionsAfterSplit = splitOptions(option.Options);
          remove(optionsAfterSplit, function (o) {
            return o == manualVentOptionToRemove;
          });
          
          tempOptionsArrayRight.push(optionsAfterSplit[0]);
        }
        else {
          tempOptionsArrayRight.push(option.DefaultValue);
        }
      });
      setSelectedCustomOptionsRight(tempOptionsArrayRight);
    }

    if (customOptionsGroupLeft) {
      let tempOptionsArrayLeft = [];
      customOptionsGroupLeft.forEach((option) => {
        if(manualVent && option.DefaultValue == manualVentOptionToRemove) {
          var optionsAfterSplit = splitOptions(option.Options);
          remove(optionsAfterSplit, function (o) {
            return o == manualVentOptionToRemove;
          });
          
          tempOptionsArrayLeft.push(optionsAfterSplit[0]);
        }
        else {
          tempOptionsArrayLeft.push(option.DefaultValue);
        }
      });
      setSelectedCustomOptionsLeft(tempOptionsArrayLeft);
    }
  }, [customOptionsGroupLeft, customOptionsGroupRight, manualVent]);

  useEffect(() => {
    // Set customOptions for order
    const tempITECustomOptionsForOrder = {
      right: selectedCustomOptionsRight,
      left: selectedCustomOptionsLeft,
    };
    setITECustomOptionsForOrder(tempITECustomOptionsForOrder);
  }, [selectedCustomOptionsRight, selectedCustomOptionsLeft]);

  const handleCustomOptionsChangeRight = (event, index) => {
    const selectedShell = event.target.value;
    let tempOptionsArrayRight = [...selectedCustomOptionsRight];
    tempOptionsArrayRight.splice(index, 1, selectedShell);
    // customOptionsGroupRight[index].selectedValue = defaultValue;

    setSelectedCustomOptionsRight(tempOptionsArrayRight);
    if (linked && iteModelLinked) {
      setSelectedCustomOptionsLeft(tempOptionsArrayRight);
    }
  };

  const handleCustomOptionsChangeLeft = (event, index) => {
    // SelectedCustomOptions is an array so when one changes we pass through the index and splice in the updated value at that location
    const selectedShell = event.target.value;
    let tempOptionsArrayLeft = [...selectedCustomOptionsLeft];
    tempOptionsArrayLeft.splice(index, 1, selectedShell);

    setSelectedCustomOptionsLeft(tempOptionsArrayLeft);
    if (linked && iteModelLinked) {
      setSelectedCustomOptionsRight(tempOptionsArrayLeft);
    }
  };

  const splitOptions = (options) => {
    // Options for each dropdown come through as a string seperated by |
    // This function splits them to an array of options
    return options.split("|");
  };

  return (
    <div>
      {(customOptionsGroupLeft || customOptionsGroupRight) && (
        (size(customOptionsGroupLeft) > 0 || size(customOptionsGroupRight) > 0) &&
        (rightSelected || leftSelected) && (
          <div className="ite-custom-options-component-main-div order-builder-component">
            <Row className="order-builder-component-title">
              <Col xs={4}>
                <Row>
                  <Col md={12}>
                    <h2>Choose options</h2>
                  </Col>
                  <Col md={12}>
                    {(rightSelected || leftSelected) && hasAdvancedSettings && (
                      <div className="">
                        <h4>Advanced Mode</h4>
                        <label className="switch2">
                          <input
                            type="checkbox"
                            value={advancedMode}
                            onChange={() => setAdvancedMode(!advancedMode)}
                          />
                          <span className="slider2"></span>
                        </label>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col md={5}>
                    {rightSelected && (
                      <div className="text-right">
                        <h3>Right Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    {rightSelected && leftSelected && (
                      <div className="text-center link-div">
                        {linked && iteModelLinked ? (
                          <InsertLink
                            className="linked"
                            onClick={() => setLinked(false)}
                          />
                        ) : (
                          <LinkOff
                            onClick={() => setLinked(!linked)}
                            className="unlinked"
                          />
                        )}
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    {leftSelected && (
                      <div>
                        <h3>Left Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    {rightSelected && Array.isArray(customOptionsGroupRight) && (
                      <Row className="ite-custom-options-row-right">
                        {customOptionsGroupRight.map((options, index) => {
                          var optionsAfterSplit = splitOptions(options.Options);
                          if (manualVent) {
                            remove(optionsAfterSplit, function (o) {
                              return o == manualVentOptionToRemove;
                            });
                          }
                          return (
                            <Col key={index} xs={12} className="ite-custom-options-div">
                              <Row>
                                <Col md={6}>
                                  <div className="select">
                                    {(!options.IsAdvanced || advancedMode) && (
                                      <select
                                        name={`${options.Name}Right`}
                                        value={selectedCustomOptionsRight[index]}
                                        onChange={() =>
                                          handleCustomOptionsChangeRight(
                                            event,
                                            index
                                          )
                                        }
                                      >
                                        {optionsAfterSplit && optionsAfterSplit.map(
                                          (option, i) => (
                                            <option key={i} value={option}>
                                              {option}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    )}
                                    {options.IsAdvanced && !advancedMode && (
                                      <p>{selectedCustomOptionsRight[index]}</p>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <p>{options.Name}</p>
                                </Col>
                              </Row>
                            </Col>
                          )
                        })}
                      </Row>
                    )}
                  </Col>
                  <Col md={6} className="left-side">
                    {leftSelected && Array.isArray(customOptionsGroupLeft) && (
                      <Row className="ite-custom-options-row-left">
                        {customOptionsGroupLeft.map((options, index) => {
                          var optionsAfterSplit = splitOptions(options.Options);
                          if (manualVent) {
                            remove(optionsAfterSplit, function (o) {
                              return o == manualVentOptionToRemove;
                            });
                          }
                          return (
                            <Col key={index} xs={12} className="ite-custom-options-div">
                              <Row>
                                <Col md={6}>
                                  <div className="select">
                                    {(!options.IsAdvanced || advancedMode) && (
                                      <select
                                        name={`${options.Name}Left`}
                                        value={selectedCustomOptionsLeft[index]}
                                        onChange={() =>
                                          handleCustomOptionsChangeLeft(
                                            event,
                                            index
                                          )
                                        }
                                      >
                                        {optionsAfterSplit && optionsAfterSplit.map(
                                          (option, i) => (
                                            <option key={i} value={option}>
                                              {option}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    )}
                                    {options.IsAdvanced && !advancedMode && (
                                      <p className="">{selectedCustomOptionsRight[index]}</p>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <p className="">{options.Name}</p>
                                </Col>
                              </Row>
                            </Col>
                          )
                        }
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )
      )}
    </div>
  );
};

export default ITECustomOptions;
