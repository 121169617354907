import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { filter, uniqBy, findIndex, find } from "lodash";
import InsertLink from "@material-ui/icons/InsertLink";
import LinkOff from "@material-ui/icons/LinkOff";
import(
  /* webpackChunkName: "order-builder-ear-pieces-receivers" */ "./earPiecesReceivers.scss"
);

import testModel from "../../../../assets/img/ModelTest.png";

const EarPiecesReceivers = ({
  setEarPiecesReceiversForOrder,
  fullListOfEarPiecesReceiversLeft,
  fullListOfEarPiecesReceiversRight,
  setRightEarPiecesReceivers,
  setLeftEarPiecesReceivers,
  rightEarPiecesReceivers,
  leftEarPiecesReceivers,
  activeRightEarPiecesReceiver,
  setActiveRightEarPiecesReceivers,
  activeLeftEarPiecesReceiver,
  setActiveLeftEarPiecesReceivers,
  leftSelected,
  rightSelected,
  iteModelLinked,
}) => {
  const [linked, setLinked] = useState(true);

  useEffect(() => {
    // When recievers avaliable are updated, set the first receiver in the array to be the active / selected one.
    if(leftEarPiecesReceivers){
      setActiveLeftEarPiecesReceivers(leftEarPiecesReceivers[0].PerformanceLevel);
    }
    if(rightEarPiecesReceivers){
      setActiveRightEarPiecesReceivers(rightEarPiecesReceivers[0].PerformanceLevel);
    }
  }, [leftEarPiecesReceivers, rightEarPiecesReceivers]);

  useEffect(() => {
    // Set active receivers for filtering lengths
    if(fullListOfEarPiecesReceiversRight){
      let tempReceiversRight = [...fullListOfEarPiecesReceiversRight];
      const receiversRight = uniqBy(tempReceiversRight, "PerformanceLevel", []);
      setRightEarPiecesReceivers(receiversRight);
    }
    
    if(fullListOfEarPiecesReceiversLeft){
      let tempReceiversLeft = [...fullListOfEarPiecesReceiversLeft];
      const receiversLeft = uniqBy(tempReceiversLeft, "PerformanceLevel", []);
      setLeftEarPiecesReceivers(receiversLeft);
    }
  }, [fullListOfEarPiecesReceiversLeft, fullListOfEarPiecesReceiversRight]);

  return (
    <div>
      {(rightEarPiecesReceivers ||
        leftEarPiecesReceivers) && (
        <div className="earPiecesReceivers-component-main-div order-builder-component">
          <Row className="order-builder-component-title">
            <Col xs={4}>
              <h2>Choose a receiver</h2>
            </Col>
            <Col xs={8}>
              <Row className="pt-4">
                <Col md={5}>
                  {rightSelected && (
                    <div className="text-right">
                      <h3>Right Ear</h3>
                    </div>
                  )}
                </Col>
                <Col md={2}>
                  {rightSelected && leftSelected && (
                    <div className="text-center link-div">
                      {linked && iteModelLinked ? (
                        <InsertLink
                          className="linked"
                          onClick={() => setLinked(false)}
                        />
                      ) : (
                        <LinkOff
                          onClick={() => setLinked(!linked)}
                          className="unlinked"
                        />
                      )}
                    </div>
                  )}
                </Col>
                <Col md={5}>
                  {leftSelected && (
                    <div>
                      <h3>Left Ear</h3>
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  {rightSelected && (
                    <Row className="earPiecesReceivers-top-left-row">
                      {rightEarPiecesReceivers &&
                        rightEarPiecesReceivers.map(
                          (receiver, index) => (
                            <Col key={index} md={6} lg={3}>
                              <a
                                onClick={() => {
                                  setActiveRightEarPiecesReceivers(receiver.PerformanceLevel);
                                  if(linked && iteModelLinked){
                                    setActiveLeftEarPiecesReceivers(receiver.PerformanceLevel);
                                  }
                                }}
                              >
                                <div
                                  className={`earPiecesReceivers-box ${
                                    activeRightEarPiecesReceiver ===
                                    receiver.PerformanceLevel
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div className="earPiecesReceivers-box-content">
                                    <div className="earPiecesReceivers-box-code">
                                      <p>{receiver.PerformanceLevel}</p>
                                    </div>
                                    <div className="earPiecesReceivers-box-text">
                                      <p>
                                        {receiver.PerformanceLevel &&
                                        receiver.PerformanceLevel == "S"
                                          ? "(Standard)"
                                          : receiver.PerformanceLevel == "M"
                                          ? "(Moderate)"
                                          : receiver.PerformanceLevel == "P"
                                          ? "(Power)"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </Col>
                          )
                        )}
                    </Row>
                  )}
                </Col>
                <Col md={6} className="left-side">
                  {leftSelected && (
                    <Row className="earPiecesReceivers-top-right-row">
                      {leftEarPiecesReceivers &&
                        leftEarPiecesReceivers.map(
                          (receiver, index) => (
                            <Col key={index} md={6} lg={3}>
                              <a
                                onClick={() => {
                                  setActiveLeftEarPiecesReceivers(
                                    receiver.PerformanceLevel
                                  );
                                  if(linked && iteModelLinked){
                                    setActiveRightEarPiecesReceivers(receiver.PerformanceLevel);
                                  }
                                }}
                              >
                                <div
                                  className={`earPiecesReceivers-box ${
                                    activeLeftEarPiecesReceiver ===
                                    receiver.PerformanceLevel
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div className="earPiecesReceivers-box-content">
                                    <div className="earPiecesReceivers-box-code">
                                      <p>{receiver.PerformanceLevel}</p>
                                    </div>
                                    <div className="earPiecesReceivers-box-text">
                                      <p>
                                        {receiver.PerformanceLevel &&
                                        receiver.PerformanceLevel == "S"
                                          ? "(Standard)"
                                          : receiver.PerformanceLevel == "M"
                                          ? "(Moderate)"
                                          : receiver.PerformanceLevel == "P"
                                          ? "(Power)"
                                          : receiver.PerformanceLevel}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </Col>
                          )
                        )}
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default EarPiecesReceivers;
