import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import EarPiecesFamilyModel from "../EarPiecesFamilyModel/EarPiecesFamilyModel";
import { OrderBuilderStatus } from "../../store/constants";
import Loading from "../../../../components/Loading/Loading";
import { uniqBy, uniq, map, filter, concat, findIndex, find } from "lodash";
import { Row, Col, Button, Form } from "react-bootstrap";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { OrderStatus } from "../../../storeHome/store/constants";

import(
  /* webpackChunkName: "order-builder-ite" */ "./accRgrChgItemBuilder.scss"
);

const AccRgrChgItemBuilder = ({
  cartData,
  productsData,
  addOrderToCart,
  orderBuilderData,
  orderBuilderStatus,
  loadOrderBuilder,
  setIsStockOrder,
}) => {
  let history = useHistory();
  const { categoryId, productId, itemId } = useParams();
  const initialProductCategory = productId;
  const initialModelCode = itemId;
  //State used on this page. Split into groups of components
  const [selectedModel, setSelectedModel] = useState();
  const [relatedProductsDataByCategory, setRelatedProductsDataByCategory] = useState([]);
  const [productAddedToCart, setProductAddedToCart] = useState();
  const [uniqueProductCategories, setUniqueProductCategories] = useState();
  const [localRelatedProductsData, setLocalRelatedProductsData] = useState();
  const [activeRelatedProductCategory, setActiveRelatedProductCategory] = useState();
  const [alreadyClickProductArray, setAlreadyClickProductArray] = useState([]);
  const [inCartTotalsAdded, setInCartTotalsAdded] = useState(false);
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);
  
  useEffect(() => {
    if (loadOrderBuilder && selectedModel) {
      //Load earpiece order builder data based on model selected in dropdowns
      if (categoryId === "AccRgrChg" && itemId) {

        loadOrderBuilder("AccRgrChg", selectedModel);
      }
      else {
        loadOrderBuilder(productId, selectedModel);

      }
    }
  }, [selectedModel]);


  useEffect(() => {
    // check if orderbuilder is an array first
    if (orderBuilderData && Array.isArray(orderBuilderData)) {
      const AccRgrChg = find(productsData, { "Code": "AccRgrChg" });
      //Get all related products
      let tempRelatedProductsArray = [...orderBuilderData];

      //Get uniqueProductCategories
      let uniqueCategoryNamesFromAllItems = uniqBy(
        AccRgrChg.InverseParentCodeNavigation,
        "Code"
      );

      const currentItemsWithUniqueCategory = uniqBy(tempRelatedProductsArray,
        "CategoryCode"
      );

      const finalUniqueCategories = uniqueCategoryNamesFromAllItems.filter(category =>
        currentItemsWithUniqueCategory.some(item => item.CategoryCode === category.Code)
      );

      let uniqueCategoryNames = finalUniqueCategories.sort();

      setUniqueProductCategories(uniqueCategoryNames);
      setActiveRelatedProductCategory(uniqueCategoryNames[0].Code);

      var tempLocalRelatedProductsData = orderBuilderData.map(x => {
        x.AddToCartQuantity = 1;
        x.QuantityInCart = 0;
        return x
      });
      if (cartData) {
        tempLocalRelatedProductsData = tempLocalRelatedProductsData.map(x => {
          x.AddToCartQuantity = 1;
          x.QuantityInCart = getQuantityInCart(x);
          return x
        });
        setInCartTotalsAdded(true);

      };
      setLocalRelatedProductsData(tempLocalRelatedProductsData);
    }
  }, [orderBuilderData]);


  // Update productsByCategory when selected category is changed
  useEffect(() => {
    if (localRelatedProductsData && orderBuilderData) {
      let tempRelatedProductsDataByCategory = [...orderBuilderData];
      let tempRelatedProductsArray = [...orderBuilderData];

      //Get uniqueProductCategories
      let uniqueCategoryNames = uniqBy(
        tempRelatedProductsArray,
        "Code"
      );

      tempRelatedProductsDataByCategory = filter(
        tempRelatedProductsDataByCategory,
        { CategoryCode: activeRelatedProductCategory }
      );
      setRelatedProductsDataByCategory(tempRelatedProductsDataByCategory);
    }
  }, [activeRelatedProductCategory, orderBuilderData]);


  const getQuantityInCart = (product) => {
    let quantityInCart = 0;
    var index = findIndex(cartData, { Code: product.Code });
    if (index >= 0) {
      quantityInCart = cartData[index].Quantity;
    }
    return quantityInCart;

  };
  const goToCart = () => {
    history.push({ pathname: "/cart" });
  };
  // handle add to cart
  const handleOnClick = (product) => {
    // Prevent multiple clicks during cart update
    if (isUpdatingCart) {
      return;
    }
  
    setIsUpdatingCart(true); // Set updating state to true
  
    console.log("Add to Cart Start - ", product);
    let tempCartData = [];
  
    // Clone current cart data
    if (cartData) {
      tempCartData = [...cartData];
      console.log("Add to Cart - ", tempCartData);
    }
  
    let quantityInCart = 0;
    const existingProductIndex = tempCartData.findIndex((e) => e.Code === product.Code);
  
    if (existingProductIndex !== -1) {
      // Update quantity if product is already in cart
      tempCartData[existingProductIndex].Quantity += Number(product.AddToCartQuantity);
      quantityInCart = tempCartData[existingProductIndex].Quantity;
    } else {
      // Add new product to cart
      product.Quantity = Number(product.AddToCartQuantity);
      quantityInCart = product.Quantity;
      product.ItemCode = product.Code;
      product.Type = "Accessories";
      tempCartData.push(product);
    }
  
    // Update cart and product quantities
    setIsStockOrder(OrderStatus.REGULAR);
    addOrderToCart(tempCartData);
    updateQuantityInCart(product, quantityInCart);
  
    // Update the clicked product array to track already clicked products
    const newAlreadyClickProductArray = [...alreadyClickProductArray, product.Code];
    setAlreadyClickProductArray(newAlreadyClickProductArray);
  
    // Reset updating state after cart update
    setTimeout(() => {
      setIsUpdatingCart(false);
    }, 500); // Adjust the timeout duration as necessary
  };

  // handle change in AddToCartQuantity for item
  const startChangeQuantity = (event, item) => {
    let tempLocalRelatedProductsData = [...localRelatedProductsData];
    // Find item index in array of items in cart by using the item code of the item passed into function
    var index = findIndex(tempLocalRelatedProductsData, { Code: item.Code });
    let tempItem = item;
    // Update the quanitity to value inputed by user
    tempItem.AddToCartQuantity = event.target.value;
    //console.log("Changing AddToCartQuantity:", index,tempItem);
    // Replace item at index using native splice
    tempLocalRelatedProductsData.splice(index, 1, tempItem);
    // set updated array to state
    setLocalRelatedProductsData(tempLocalRelatedProductsData);
    //console.log("localRelatedProductsData: ",localRelatedProductsData);
  };
  const updateQuantityInCart = (item, quantityInCart) => {
    let tempLocalRelatedProductsData = [...localRelatedProductsData];
    // Find item index in array of items in cart by using the item code of the item passed into function
    var index = findIndex(tempLocalRelatedProductsData, { Code: item.Code });
    let tempItem = item;
    // Update the quanitity to value inputed by user
    tempItem.QuantityInCart = Number(quantityInCart);
    // Replace item at index using native splice
    tempLocalRelatedProductsData.splice(index, 1, tempItem);
    // set updated array to state
    setLocalRelatedProductsData(tempLocalRelatedProductsData);
  };
  const handleConfigureOnClick = (product) => {
    //console.log("productAddedToCart",productAddedToCart)
    const earpiecesPath = "/store/Earpieces/" + productAddedToCart.CategoryCode + "/" + productAddedToCart.ModelTypeCode;
    history.push({
      pathname: earpiecesPath,
      state: { product: product },
    });
  };

  const splitPascalCaseWithLodash = (text) => {
    return text.replace(/([A-Z][a-z]+)/g, ' $1').trim();
  };

  return (
    <div className="order-builder-main-div related-products-main-div acc-rgr-chg-main-div">
      <h1 style={{ color: "#222222" }}>Accessories / Roger / Chargers</h1>
      <div>
        <div>
          <EarPiecesFamilyModel
            productsData={productsData}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            initialProductCategory={initialProductCategory}
            initialModelCode={initialModelCode}
          />
          {orderBuilderStatus === OrderBuilderStatus.LOADING && <Loading />}
          {orderBuilderStatus === OrderBuilderStatus.SUCCESS && (
            <div>
              {localRelatedProductsData && (
                <div>
                  <Row className="related-item-categories-row mt-5">
                    {uniqueProductCategories.map((productName, index) => {
                      const title = splitPascalCaseWithLodash(productName.Name)
                      return (
                        <Col
                          md={2}
                          xs={12}
                          key={index}
                          className={
                            activeRelatedProductCategory === productName.Code
                              ? "active related-item-category-div"
                              : "related-item-category-div"
                          }
                          onClick={() =>
                            setActiveRelatedProductCategory(productName.Code)
                          }
                        >
                          <h3>{title}</h3>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className="related-product-cards">
                    {relatedProductsDataByCategory &&
                      relatedProductsDataByCategory.length > 0 &&
                      relatedProductsDataByCategory.map((product, index) => {
                        return (
                          <Col
                            md={2}
                            xs={12}
                            key={product.Code}
                            className="related-product-cols"
                          >
                            <div className="related-product-card">
                              <div className="related-product-title-div">
                                <h3 className="related-product-category-title">
                                  {product.Name}
                                </h3>
                              </div>
                              <div className="related-product-img">
                                <span className="helper"></span>
                                <img
                                  src={product.ImagePath}
                                  className="img-fluid"
                                />
                              </div>
                              <p>{product.Code}     {product.QuantityInCart > 0 ? <span><ShoppingCartIcon />{product.QuantityInCart}</span> : ""}</p>
                              {product.CategoryCode == "Earpieces" ? (
                                <a
                                  onClick={() => handleConfigureOnClick(product)}
                                  className="btn btn-secondary related-product-button"
                                >
                                  Configure
                                </a>
                              ) : (
                                <div className="add-related-to-cart">
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    defaultValue={product.AddToCartQuantity}
                                    onChange={(event) =>
                                      startChangeQuantity(event, product)
                                    }
                                    min="1"
                                    className="me-2"
                                    aria-label="Stock"
                                  />
                                  <a
                                    onClick={() => handleOnClick(product)}
                                    className={`btn btn-primary related-product-button ${isUpdatingCart ? "disabled-add-to-cart" : ""}`}
                                    disabled={isUpdatingCart}
                                  >Add to cart</a>
                                </div>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    {relatedProductsDataByCategory &&
                      relatedProductsDataByCategory.length <= 0 && (
                        <p>Sorry, there are no items available in this category for your selected model at the moment. Please explore other categories or choose a different model.</p>
                      )}
                  </Row>
                  <Row className="go-to-cart-button-button-div mt-5">
                    <Col xs={12}>
                      <div className="go-to-cart-button-div">
                        <Button className="float-right" onClick={goToCart}>
                          Checkout
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccRgrChgItemBuilder;