import React, { useEffect, useState } from "react";
import config from '../../../../config.js'
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sortBy, filter } from "lodash";

// Webpack is configured to grab SCSS files and compile them so this is just a way of grabbing the scss file and naming it in webpack.
import(/* webpackChunkName: "top-level-category" */ "./topLevelCategory.scss");

const TopLevelCategory = ({ loadProducts, productsStatus, productsData }) => {
  const [validData, setValidData] = useState([])
  // useEffect runs when a component first mounts, or when related data changes
  useEffect(() => {
    if (loadProducts) {
      loadProducts("");
    }
  }, [loadProducts]);

  // Checks if Product Data (Top level categories) have been loaded then filters out null data and any products with no Type set
  
  useEffect(() => {
    if (productsData) {
      const validProducts = filter(productsData, (product) => product != null && product.Type);

      // Set's the valid Product data to a local state. This is only accessable in this file, defined at the start of the component 
      // and can be updated by using setValidData and accessed using validData.
      setValidData(validProducts);
    }
  }, [productsData]);

  return (
    <Col>
      <div className="top-level-category-main-div">
        <div className="top-level-category-content">

          {/* Sets home page text content based on config set */}
          {config.siteName && config.siteName === "PHONAK" &&
            <div>
              <h1>Welcome to the Phonak NZ eStore!</h1>
              <p>The intuitive work flow enables you to easily place orders for:</p>
              <ul>
                <li>
                  RIC and BTE hearing instruments with external receivers /
                  SlimTubing and domes
                </li>
                <li>RIC and BTE hearing instruments with custom ear pieces</li>
                <li>Custom ear pieces</li>
                <li>ITE hearing instruments</li>
                <li>
                  Chargers, and any accessories and/or Roger required to accompany
                  your hearing instrument fittings.
                </li>
              </ul>

              <p>Happy ordering!</p>
              <p>Together we change lives</p>
            </div>
          }
          {
            config.siteName && config.siteName === "UNITRON" &&
            <div>
              <h1>Welcome to the Unitron NZ eStore!</h1>
              <p>The intuitive work flow enables you to easily place orders for:</p>
              <ul>
                <li>
                  RIC and BTE hearing instruments with external receivers /
                  SlimTubing and domes
                </li>
                <li>RIC and BTE hearing instruments with custom ear pieces</li>
                <li>Custom ear pieces</li>
                <li>ITE hearing instruments</li>
                <li>Chargers and any accessories required to accompany your hearing instrument fittings.</li>
              </ul>

              <p>Happy ordering!</p>
              <p>Enjoy the experience</p>
            </div>
          }
        </div>
        <Row className="top-level-category-cards">
          {validData &&
            validData.length > 0 &&
            validData.map((product, index) => {
              // loops through validData, that was set earlier, and displays cards that link to each category's main page.
              // NOTE: product is actually a category.
              return (
                <Col
                  xs={12}
                  md={6}
                  xl={4}
                  key={index}
                  className={`${index % 3 == 2 ? "" : "card-right-border"} my-3`}
                >
                  <Link
                    to={
                      (product.Name == "BTE/RICs" || product.Name == "ITEs" || product.Name == "Stock Orders")
                        ? `/store/${product.Code}`
                        : `/store/${product.Code}/${product.Type}`
                    }
                  >
                    <div className="top-level-card">
                      <div className="top-level-img">
                        {/* <span className="helper"></span> */}
                        <img src={product.ImagePath} />
                      </div>
                      <div>
                        <h2 className="top-level-category-title">
                          {product.Name}
                        </h2>
                        <p className="btn">Order Now</p>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
        </Row>
      </div>
    </Col>
  );
};

//Defining types of props (similar to typescript) to prevent developer from using variables in another way and causing errors.
TopLevelCategory.propTypes = {
  loadProducts: PropTypes.func,
  productsStatus: PropTypes.string,
  productsData: PropTypes.array,
};

export default TopLevelCategory;
