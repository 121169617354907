import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(/* webpackChunkName: "order-builder-ite-shell-style" */ "./iteShellStyle.scss");
import InsertLink from '@material-ui/icons/InsertLink';
import { head } from "lodash";
import LinkOff from '@material-ui/icons/LinkOff';
import testModel from "../../../../assets/img/ModelTest.png";

const ITEShellStyle = ({
  iteShellStyleLeft,
  iteShellStyleRight,
  selectedShellStyleRight,
  setSelectedShellStyleRight,
  selectedShellStyleLeft,
  setSelectedShellStyleLeft,
  leftSelected,
  rightSelected,
  iteModelLinked,
  selectedModel
}) => {
  const [activeRightITEShellStyle, setActiveRightITEShellStyle] = useState();
  const [activeLeftITEShellStyle, setActiveLeftITEShellStyle] = useState();
  const [linked, setLinked] = useState(true);

  // Set default active values
  useEffect(() => {
    // console.log("ShellStyle loading", selectedModel, rightSelected, leftSelected)
    if (iteShellStyleLeft) {
      let selectedShellStyleTemp = head(iteShellStyleLeft);
      // console.log("iteShellStyleLeft", iteShellStyleLeft);
      // console.log("selectedShellStyleTemp Left", selectedShellStyleTemp);
      setSelectedShellStyleLeft(selectedShellStyleTemp);
    }
    if (iteShellStyleRight) {
      let selectedShellStyleTemp = head(iteShellStyleRight);
      // console.log("iteShellStyleRight", iteShellStyleRight);
      // console.log("selectedShellStyleTemp Right", selectedShellStyleTemp);
      setSelectedShellStyleRight(selectedShellStyleTemp);
    }
  }, [iteShellStyleRight, iteShellStyleLeft, selectedModel, rightSelected, leftSelected])

  // Set shell style for order
  // Set other side if still linked
  const setRightActive = (shellStyle) => {
    setActiveRightITEShellStyle(shellStyle);

    if (linked && iteModelLinked) {
      setActiveLeftITEShellStyle(shellStyle);
    }
  };

  const setLeftActive = (shellStyle) => {
    setActiveLeftITEShellStyle(shellStyle);
    if (linked && iteModelLinked) {
      setActiveRightITEShellStyle(shellStyle);
    }
  };

  const handleShellStyleChangeRight = (event) => {
    const selectedShell = event.target.value;
    setSelectedShellStyleRight(selectedShell);
    if (linked && iteModelLinked) {
      setSelectedShellStyleLeft(selectedShell);
    }
  };

  const handleShellStyleChangeLeft = (event) => {
    const selectedShell = event.target.value;
    setSelectedShellStyleLeft(selectedShell);
    if (linked && iteModelLinked) {
      setSelectedShellStyleRight(selectedShell);
    }
  };

  return (
    <div>
      {(iteShellStyleLeft || iteShellStyleRight) &&
        (rightSelected || leftSelected) && (
          <div className="ite-shell-style-component-main-div order-builder-component">
            <Row className="order-builder-component-title">
              <Col xs={4}>
                <h2>Choose a shell style</h2>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col xs={5}>
                    {rightSelected && (
                      <p className="right-ear-title ear-impression-option ear-impression-option mb-0">
                        <span className="dot dot-right"></span>Right Ear
                      </p>
                    )}
                  </Col>
                  <Col xs={2}>
                    {rightSelected && leftSelected && (
                      <div className="text-center link-div">
                        {linked && iteModelLinked ? <InsertLink className="linked" onClick={() => setLinked(false)} /> : <LinkOff onClick={() => setLinked(!linked)} className="unlinked" />}
                      </div>
                    )}
                  </Col>
                  <Col xs={5}>
                    {leftSelected && (
                      <p className="left-ear-title ear-impression-option ear-impression-option mb-0">
                        <span className="dot dot-left"></span>Left Ear
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    {rightSelected && (
                      <Row className="ite-shell-style-row-right">
                        <Col xs={12}>
                          <div className="select">
                            <select
                              value={selectedShellStyleRight}
                              onChange={handleShellStyleChangeRight}
                            >
                              {iteShellStyleRight && iteShellStyleRight.map((shellStyle, index) => {
                                return (
                                  <option key={index} value={shellStyle.Code}>
                                    {shellStyle.Name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md={6}>
                    {leftSelected && (
                      <Row className="ite-shell-style-row-left left-side">
                        <Col xs={12}>
                          <div className="select">
                            <select
                              value={selectedShellStyleLeft}
                              onChange={handleShellStyleChangeLeft}
                            >
                              {iteShellStyleLeft && iteShellStyleLeft.map((shellStyle, index) => {
                                return (
                                  <option key={index} value={shellStyle.Code}>
                                    {shellStyle.Name}
                                  </option>
                                )
                              }
                              )}
                            </select>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};

export default ITEShellStyle;
