import { connect } from 'react-redux';

// import view
import TopLevelCategory from './topLevelCategory';
// functions to get/set data from redux
import { getProductsStatus, getProductsData } from '../../store/selectors';
// functions to get data from APIs and save to redux
import { loadProducts } from '../../store/action-creators';

//gets data from redux and sets to variables
// NOTE: productsData is actually the full catalogue - categories , sub categories, products
const mapStateToProps = (state) => ({
  productsStatus: getProductsStatus(state),
  productsData: getProductsData(state),
});
// pass the api call function to the view
const mapDispatchToProps = {
  loadProducts
}

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelCategory);