// https://redux-saga.js.org/
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { LOAD_PRODUCTS_DATA } from './actions';
import { ProductsStatus } from './constants';
import { get, findIndex } from 'lodash';
import api from '../../../utilities/api';
import Cookies from 'universal-cookie';

export function* rootSaga() {
  yield takeLatest(LOAD_PRODUCTS_DATA, loadProducts);
}

export function* loadProducts(data) {
  try {
    yield put(actionCreators.setProductsStatus(ProductsStatus.LOADING));
    // console.log("data", data);
    if(data) {
      const productId = get(data, "payload", "");
      const currentProductsData = get(data, "0", "");
      // console.log("productId", productId);
      // console.log("currentProductsData", currentProductsData);

      const cookies = new Cookies();
      const token = cookies.get('LoginToken');  // does this throw error when there is no loginToken? This will logout if there is no token. Handled in the private layout
      const headerParams = {
        Authorization: `Bearer ${token}`
      };
      let productDataArray = [];
      // console.log("productId == StockOrders", productId == "StockOrders");

      if(productId == "StockOrders") {
        const response = yield call(api.get, '/api/item/stockorders', {headers:headerParams});
        const index = findIndex(currentProductsData, { Code: 'StockOrders' });
        if(index !== -1) {
          productDataArray = [...response.data];
          currentProductsData[index].InverseParentCodeNavigation = productDataArray;
          // console.log("currentProductsData", currentProductsData);
          yield put(actionCreators.setProductsData(currentProductsData));
          yield put(actionCreators.setProductsStatus(ProductsStatus.SUCCESS));
        }
      }
      else {
        const response = yield call(api.get, '/api/category', {headers:headerParams});
        productDataArray = [...response.data];
        // console.log(response.data);
        // console.log(response.data);
        
        // productDataArray = filter(response.data, item => item.ParentCode == null && item.InverseParentCodeNavigation.length > 0);
        // const earpiecesType = find(response.data, item => item.Type == "Earpiece");
        // productDataArray.push(earpiecesType);
        // const accRgrChgType = find(response.data, item => item.Type == "AccRgrChg");
        // productDataArray.push(accRgrChgType);
        
        //console.log("productDataArray", productDataArray);
        
        yield put(actionCreators.setProductsData(productDataArray));
        yield put(actionCreators.setProductsStatus(ProductsStatus.SUCCESS));
      }
    }
  }
  catch(error) {
    yield put(actionCreators.setProductsStatus(ProductsStatus.ERROR));
  }
}