import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { sortBy } from "lodash";
import(
  /* webpackChunkName: "order-builder-performance-level" */ "./performanceLevel.scss"
);

const PerformanceLevel = ({
  setPerformanceLevelForOrder,
  performanceLevelCodes,
}) => {
  const [activePerformanceLevel, setActivePerformanceLevel] = useState();
  const [tempPerformanceLevelCodes, setTempPerformanceLevelCodes] = useState([]);
  
  //Sets default active performance level code
  useEffect(() => {
    setActive();
    if(performanceLevelCodes){
      const tempPerformanceLevelCodesArray = sortBy(performanceLevelCodes, o => o.Code)
      setTempPerformanceLevelCodes(tempPerformanceLevelCodesArray);
    }
  }, [performanceLevelCodes]);
  
  //Sets active performance level for local state and for order
  const setActive = (model) => {
    setActivePerformanceLevel(model);
    setPerformanceLevelForOrder(model);
  };

  return (
    <div>
      {tempPerformanceLevelCodes && (
        <div className="performance-level-component-main-div order-builder-component">
          <h2 className="order-builder-component-title">
            Choose a performance level
          </h2>
          <Row className="">
            {tempPerformanceLevelCodes.map((performanceLevel, index) => (
              <Col key={index} md={3} >
                <a
                  onClick={() => {
                    setActive(performanceLevel.Code);
                  }}
                >
                  <div
                    className={`performance-level-box ${
                      activePerformanceLevel === performanceLevel.Code
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="performance-level-box-text">
                      <p>{performanceLevel.Name}</p>
                      {/* <p>{performanceLevel.Code}</p> */}
                    </div>
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default PerformanceLevel;
