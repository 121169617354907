import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { uniqBy, map, filter, find, remove, concat, get } from "lodash";
import(/* webpackChunkName: "order-builder-domes" */ "./domes.scss");

import testModel from "../../../../assets/img/ModelTest.png";

const Domes = ({ domes, setDomesForOrder }) => {
  const [domeTypes, setDomeTypes] = useState();
  const [domesSelected, setDomesSelected] = useState([]);

  // on component load run initial dome types function
  useEffect(() => {
    InitiateDomeTypes();
  }, []);

  // Set domes for order when new active dome is selected
  useEffect(() => {
    setDomesForOrder(domesSelected);
  }, [domesSelected]);

  // Get unique dome types for rows of domes. i.e open, cap etc.
  const InitiateDomeTypes = () => {
    let uniqueDomeTypes = [...domes];
    uniqueDomeTypes = uniqBy(uniqueDomeTypes, "PerformanceLevelCode");
    uniqueDomeTypes = map(uniqueDomeTypes, "PerformanceLevelCode");
    setDomeTypes(uniqueDomeTypes);
  };

  // Updated domes Selected array with new domes selected
  const addDomeToOrder = (domeType, dome) => {
    let tempDomesForOrder = [...domesSelected];

    tempDomesForOrder = tempDomesForOrder.filter(function (value, index, arr) {
      return value.PerformanceLevelCode != domeType;
    });

    if (dome) {
      tempDomesForOrder = concat(tempDomesForOrder, dome);
    }

    setDomesSelected(tempDomesForOrder);
  };

  // Domes row is a nested component that takes the unique dome types we set up earlier,
  // Creates a row and finds all domes under that dome type and displays them
  const DomeRow = ({ domeType, index, domes, domesSelected }) => {
    const [activeDomeCode, setActiveDomeCode] = useState();
    const [domesByType, setDomesByType] = useState();

    useEffect(() => {
      let domeSelected = filter(domesSelected, {
        PerformanceLevelCode: domeType,
      });
      if (!domeSelected.length > 0) {
        setActiveDomeCode("None");
      } else {
        setActiveDomeCode(domeSelected[0].Code);
      }
      let domesForRow = [...domes];
      domesForRow = filter(domesForRow, { PerformanceLevelCode: domeType });
      setDomesByType(domesForRow);
    }, []);

    const startAddDomeToOrder = (domeCode) => {
      if (domeCode !== "None") {
        let dome = find(domes, { Code: domeCode });
        let domeCopy = { ...dome };
        addDomeToOrder(domeType, domeCopy);
      } else {
        addDomeToOrder(domeType);
      }
    };

    return (
      <Row className="">
        <Col xs={12}>
          <h2 style={{'marginBottom': '0'}}>{domeType}</h2> 
        </Col>
        <Col md={6} md={2}>
          <a
            onClick={() => {
              startAddDomeToOrder("None");
            }}
          >
            <div
              className={`domes-box ${
                activeDomeCode == "None" ? "active" : ""
              }`}
            >
              <div className="domes-box-none-text">
                <p>None</p>
              </div>
            </div>
          </a>
        </Col>
        {domesByType &&
          domesByType.map((dome, i) => (
            <Col key={i} md={6} md={2}>
              <a
                onClick={() => {
                  startAddDomeToOrder(dome.Code);
                }}
              >
                <div
                  className={`domes-box ${
                    activeDomeCode === dome.Code ? "active" : ""
                  }`}
                >
                  <div className="domes-box-image">
                    <img src={dome.ImagePath} className="img-fluid" />
                  </div>
                  <div className="domes-box-text">
                    <p>{dome.Name}</p>
                  </div>
                </div>
              </a>
            </Col>
          ))}
      </Row>
    );
  };

  return (
    <div>
      {domeTypes && (
        <div className="domes-component-main-div order-builder-component">
          <h2 className="order-builder-component-title">
            Additional domes {"(packs of 10)"}
          </h2>
          <p>Receivers come standard with one dome of each size S/M/L. Custom earpieces can be ordered on the next page.</p>
          {domeTypes.map((domeType, index) => (
            <DomeRow
              key={index}
              index={index}
              domeType={domeType}
              domes={domes}
              domesSelected={domesSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Domes;
