import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home"; // Import the Home icon from Material-UI
import(/* webpackChunkName: "breadcrumbs-navigation" */ "./breadcrumbsNavigation.scss");

const BreadcrumbsNavigation = ({ allStockData, code }) => {
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);

  // Recursive function to find the category by code and build the breadcrumb path
  const findCategoryPathByCode = (categories, code, path = []) => {
    for (let category of categories) {
      if (category.Code === code) {
        return [...path, category];
      }
      if (category.ChildCategories && category.ChildCategories.length > 0) {
        const foundPath = findCategoryPathByCode(category.ChildCategories, code, [...path, category]);
        if (foundPath) {
          return foundPath;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (allStockData && code) {
      if (code === 'StockOrder') {
        // For StockOrder, show only the top level
        setBreadcrumbPath([]);
      } else {
        // Determine the breadcrumb path using recursive search
        const path = findCategoryPathByCode(allStockData, code);
        // console.log("Breadcrumb Path:", path);
        setBreadcrumbPath([{ Name: 'Stock / Consumables', Code: 'StockOrder' }, ...path]);
      }
    }
  }, [allStockData, code]);

  if (code === 'StockOrder') {
    return null;
  }

  return (
    <nav className="breadcrumbs">
      <ul>
        <li>
          <Link to="/">
            <HomeIcon fontSize="small" /> {/* Home icon */}
          </Link>
        </li>
        {breadcrumbPath.map((category, index) => (
          <li key={category.Code}>
            {index < breadcrumbPath.length - 1 ? (
              <Link to={`/store/StockOrders/${category.Code}`}>{category.Name}</Link>
            ) : (
              <span></span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default BreadcrumbsNavigation;
