import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(/* webpackChunkName: "order-builder-select-sides" */ "./selectSides.scss");

const SelectSides = ({ rightSelected, setRightSelectedSides, leftSelected, setLeftSelected }) => {
  
  const handleChangeRight = () => {
    setRightSelectedSides(!rightSelected);
  };

  const handleChangeLeft = () => {
    setLeftSelected(!leftSelected);
  };

  return (
    <div className="select-sides-component-main-div order-builder-component">
      <div className="select-sides-div">
        <div className="select-sides-form">
          <div>
            <form>
              <Row className="order-builder-component-title">
                <Col xs={4}>
                  <h2>Select side</h2>
                </Col>
                <Col xs={4}>
                  <div className="form-group">
                    <input type="checkbox" id="RightEar" checked={rightSelected} onChange={handleChangeRight} />
                    <label htmlFor="RightEar">Right Ear</label>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="form-group">
                    <input type="checkbox" id="LeftEar" value={leftSelected} onChange={handleChangeLeft} />
                    <label htmlFor="LeftEar">Left Ear</label>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectSides;
