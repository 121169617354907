import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { OrderConfirmationStatus } from "./store/constants";
import Loading from "../../components/Loading/Loading";
import { parse, isValid, addDays, isWeekend, format, startOfDay } from 'date-fns';
import { Link } from "react-router-dom";
import { setErrorStatus } from "../organisations/store/action-creators";
import { sortBy, some, find } from 'lodash';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import(/* webpackChunkName: "repairCheckout" */ "./repairCheckout.scss");

const RepairCheckout = ({
  submitRepairOrder,
  orderConfirmationStatus,
  orderConfirmationData,
  sentOrderConfirmationStatus,
  orderNumber,
  cartData,
  addOrderToCart,
  loadOrderConfirmation,
  resetOrderNumber,
  profileData,
}) => {
  const [orderShippingRate, setOrderShippingRate] = useState("standard");
  const [selectedSite, setSelectedSite] = useState(0);
  const [requiredByDate, setRequiredByDate] = useState();
  const [siteError, setSiteError] = useState();
  const [siteList, setSiteList] = useState([]);
  const [isHAFSorderChecked, setIsHAFSorderChecked] = useState(false);
  const [isVAClientChecked, setIsVAClientChecked] = useState(false);
  const [hideVACheckbox, setHideVACheckbox] = useState(false);
  const [minDate, setMinDate] = useState('');
  const [requiredByDateFocused, setRequiredByDateFocused] = useState(false);
  let history = useHistory();
  const location = useLocation();
  useEffect(() => {
    setHideVACheckbox(false);
    // console.log("profileData", profileData)
    if (profileData) {
      if (profileData.IsSpecsavers) {
        setHideVACheckbox(true);
      }
    }
  }, [profileData]);

  useEffect(() => {
    const { repairRemakeFormObject } = location.state || {};
    // console.log("repairRemakeFormObject", repairRemakeFormObject)

    if (!repairRemakeFormObject) {
      history.push({ pathname: "/store/Repair/Repair" });
    }
    // setRepairRemakeFormObject(repairRemakeFormObject)
  }, []);

  const handleHAFSorderChange = (event) => {
    setIsHAFSorderChecked(event.target.checked);
  };

  const handleVAClientChange = (event) => {
    setIsVAClientChecked(event.target.checked);
  };

  const handleSiteChange = (event) => {
    const myValue = event.target.value;
    setSelectedSite(myValue);
  };

  const addWorkingDays = (startDate, numberOfDays) => {
    let currentDate = startDate;
    let addedDays = 0;

    while (addedDays < numberOfDays) {
      currentDate = addDays(currentDate, 1);
      if (!isWeekend(currentDate)) {
        addedDays++;
      }
    }

    return currentDate;
  }

  const isWeekday = date => {
    return !isWeekend(date.toDate());
  };

  useEffect(() => {
    const today = startOfDay(new Date());
    let minimumDaysRequired = 1; // Default minimum days

    const minimumDate = addWorkingDays(today, minimumDaysRequired);
    setMinDate(format(minimumDate, 'yyyy-MM-dd'));
  }, [cartData, orderConfirmationData]);

  useEffect(() => {
    if (loadOrderConfirmation) {
      loadOrderConfirmation();
    }
  }, [loadOrderConfirmation]);

  useEffect(() => {
    if (profileData) {
      //console.log("profileData - ", profileData.Sites);
      let tempSiteList = sortBy(profileData.Sites, [function (site) { return site.SiteName; }]);
      setSiteList(tempSiteList);
    }
  }, [profileData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Gets values from the form and sets to local variables
    const {
      purchaseOrderNumber,
      specialInstructions,
      firstName,
      lastName,
      HAFSorder,
      childHAFSRepair,
      VAClient,
    } = document.forms[0];

    let tempSite = "";

    if (siteList.length == 1) {
      tempSite = siteList[0].SiteCode;
    } else {
      setSiteError();
      const { site } = document.forms[0];
      if (site.value) {
        tempSite = siteList[site.value].SiteCode;
      } else {
        setSiteError("Please select a site");
        return;
      }
    }

    // Send order to API ./store/saga
    const { repairRemakeFormObject } = location.state || {};
    let tempRequiredByDate = "";
    if(requiredByDate) {
      tempRequiredByDate = requiredByDate.format('YYYY-MM-DD');  
    }
    submitRepairOrder(
      // repairRemakeFormObject.Model,
      repairRemakeFormObject,
      orderShippingRate,
      purchaseOrderNumber.value,
      profileData.OrganisationCode,
      tempSite,
      firstName.value,
      lastName.value,
      tempRequiredByDate,
      false, // isHAFSorderChecked REMOVED but keeping value here call to saga file
      childHAFSRepair.checked,
      false, // isVAClientChecked REMOVED but keeping value here call to saga file
      // repairRemakeFormObject,
      // repairRemakeFormObject.ModelCode,
      repairRemakeFormObject.RightModel,
      repairRemakeFormObject.RightModelCode,
      repairRemakeFormObject.LeftModel,
      repairRemakeFormObject.LeftModelCode,
    );
  };

  const goToSuccessScreen = () => {
    if (sentOrderConfirmationStatus === OrderConfirmationStatus.SUCCESS && orderNumber) {
      //Clear cart
      addOrderToCart();
      loadOrderConfirmation();
      resetOrderNumber();
      let url = `/profile/order-history/${orderNumber}`;
      history.push({ pathname: `${url}` });
      // history.push({ pathname: "/order-confirmation/success" });
    }
  }


  const parseDate = (input) => {
    // Try different date formats here based on your requirements
    const formats = ['yyyy-MM-dd', 'yyyyMMdd', 'yyyy/MM/dd', 'yyyy.MM.dd', 'MM/dd/yyyy', 'MMddyyyy', 'MM-dd-yyyy', 'MM.dd.yyyy', 'dd/MM/yyyy', 'ddMMyyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'yyyy.MM.dd', 'dd/MM/yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX', 'yyyy-MM-dd\'T\'HH:mm:ss', 'yyyy-MM-dd HH:mm:ss', 'MMMM d, yyyy', 'd MMMM yyyy',];
    for (let f of formats) {
      const parsed = parse(input, f, new Date());
      if (isValid(parsed)) {
        // console.log("parsed", parsed)
        // console.log("format(parsed, 'dd-MM-yyyy')", format(parsed, 'dd-MM-yyyy'))

        return parsed;
      }
    }
    return null;
  };

  const handleDateOfBirthPaste = (e) => {
    const pastedText = e.clipboardData.getData('text');
    //console.log(pastedText);
    const parsedDate = parseDate(pastedText);
    if (parsedDate) {
      e.preventDefault();
      setDateOfBirth(format(parsedDate, 'yyyy-MM-dd'));
    }
  };
  const handleRequiredByDatePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const parsedDate = parseDate(pastedText);
    const formatedDate = format(parsedDate, 'yyyy-MM-dd');

    // console.log("formatedDate", formatedDate)
    // console.log("minDate", minDate)

    if (parsedDate && !isWeekend(formatedDate) && (formatedDate >= minDate)) {
      //minDate check parsedDate is > minDate
      // console.log("moment(formatedDate)", moment(formatedDate))

      setRequiredByDate(moment(formatedDate));
    } else {
      alert('Pasted date is invalid or falls on a weekend.');
    }
  };

  const handleRequiredByDateChange = (newDate) => {
    // console.log("newDate", newDate)
    if (newDate && !isWeekend(newDate.toDate())) {
      setRequiredByDate(newDate);
    }
  };

  //If API returns a success show a success screen
  useEffect(() => {
    goToSuccessScreen();
  }, [sentOrderConfirmationStatus, orderNumber]);

  if (sentOrderConfirmationStatus === OrderConfirmationStatus.LOADING) {
    return (
      <Col>
        <div className="">
          <Loading />
        </div>
      </Col>
    );
  }


  return (
    <div className="order-confirmation-main-div">
      <div className="order-confirmation-title">
        <h1>Repair / Remake Checkout</h1>
        <p>
          Please check the following information is correct and fill out the
          available fields if applicable.
        </p>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Processing Information</h2>
          </Col>
          <Col xs={5}>
            <table className="table-full-width">
              {profileData && profileData.FirstName && profileData.LastName && (
                <tr>
                  <th>Name</th>
                  <td>
                    {profileData.FirstName} {profileData.LastName}
                  </td>
                </tr>
              )}
              {profileData && profileData.OrganisationCode && (
                <tr>
                  <th>Organisation</th>
                  <td>{profileData.OrganisationName}</td>
                </tr>
              )}
              {siteList && siteList.length == 1 && (
                <tr>
                  <th>Site</th>
                  <td>{siteList[0] && siteList[0].SiteName}</td>
                </tr>
              )}
              <tr>
                <th>First Name *</th>
                <td>
                  <Form.Control
                    type="text"
                    name="firstName"
                    className="me-2"
                    aria-label="firstName"
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>Last Name *</th>
                <td>
                  <Form.Control
                    type="text"
                    name="lastName"
                    className="me-2"
                    aria-label="lastName"
                    required
                  />
                </td>
              </tr>
              <tr>
                <th>Required By </th>
                <td>
                  <div>
                    <div
                      // style={{ opacity: 0, height: 0, position: 'absolute', zIndex: -1 }}
                      onPaste={handleRequiredByDatePaste}
                    >
                      <SingleDatePicker
                        date={requiredByDate}
                        onDateChange={handleRequiredByDateChange}
                        focused={requiredByDateFocused}
                        onFocusChange={({ focused }) => setRequiredByDateFocused(focused)}
                        id="requiredByDate"
                        isDayBlocked={day => isWeekend(day.toDate())}
                        numberOfMonths={1}
                        isOutsideRange={day => day.isBefore(minDate)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Purchase Order #</th>
                <td>
                  <Form.Control
                    type="text"
                    name="purchaseOrderNumber"
                    className="me-2"
                    aria-label="purchaseOrderNumber"
                    required={isHAFSorderChecked || isVAClientChecked}
                  />
                </td>
              </tr>
              {/* <tr>
                <th>HAFS <br /><span className="hafs-title">{"("}Hearing Aid Funding Scheme{")"}</span></th>
                <td>
                  <Form.Control
                    type="checkbox"
                    name="HAFSorder"
                    className="me-2 hafs-checkbox"
                    aria-label="HAFSorder"
                    onChange={handleHAFSorderChange}
                  />
                </td>
              </tr> */}
              <tr>
                <th>Ko Taku Reo<br /><span className="hafs-title">{"("}Child HAFS Repair{")"}</span></th>
                <td>
                  <Form.Control
                    type="checkbox"
                    name="childHAFSRepair"
                    className="me-2 hafs-checkbox"
                    aria-label="childHAFSRepair"
                  />
                </td>
              </tr>
              {/* {!hideVACheckbox &&
                <tr>
                  <th>Veteran's Affairs {"("}VA{")"} Client<br /><span className="hafs-title">{"("}Please select when ordering specific product from VA price list, pricing PO number{")"}</span></th>
                  <td>
                    <Form.Control
                      type="checkbox"
                      name="VAClient"
                      className="me-2 hafs-checkbox"
                      aria-label="VAClient"
                      onChange={handleVAClientChange}
                    />
                  </td>
                </tr>
              } */}
            </table>
          </Col>
          <Col xs={12} md={{span: 5, offset: 1}} className="repair-category">
            <table className="table-full-width repair-category-table">
              <tr>
                <th>Category</th>
                <th>Routine in-house turn-around time*<br />(working days)</th>
              </tr>
              <tr>
                <td>Repair - Standard Product</td>
                <td>3</td>
              </tr>
              <tr>
                <td>Repair - Custom Product</td>
                <td>5</td>
              </tr>
              <tr>
                <td>Repair - Custom</td>
                <td>8</td>
              </tr>
            </table>
            <p className="subtext">*In-house TAT reflect working days in house from arrival to dispatch, are intended to provide a general guidance, and may vary depending on incoming volume &/or staffing. We are unable to guarantee inbound/outbound courier turn-times.</p>
          </Col>
          <Col xs={12}>
            {siteList && siteList.length > 1 && (
              <table className="shipping-options-top-input">
                <tr>
                  <th>SITE:</th>
                  {siteList.map((site, index) => {
                    return (
                      <td>
                        <input
                          onChange={handleSiteChange}
                          id={`site-${index}`}
                          type="radio"
                          value={index}
                          checked={selectedSite == index}
                          name="site"
                        />
                        {site.SiteName}
                      </td>
                    );
                  })}
                </tr>
              </table>
            )}
            {siteError && (
              <div className="alert alert-danger">
                <p>{siteError}</p>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          {sentOrderConfirmationStatus === OrderConfirmationStatus.ERROR && (
            <Col xs={12}>
              <div className="alert alert-danger">
                <p>
                  An error occured with submitting your order. Please try again
                </p>
              </div>
            </Col>
          )}
          <Col xs={2} className="offset-10">
            <Button type="submit" className="btn btn-primary float-right">
              Submit Order
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

RepairCheckout.propTypes = {
  submitRepairOrder: PropTypes.func,
  orderConfirmationStatus: PropTypes.string,
  sentOrderConfirmationStatus: PropTypes.string,
  orderNumber: PropTypes.string,
  cartData: PropTypes.array,
  addOrderToCart: PropTypes.func,
  loadOrderConfirmation: PropTypes.func,
  resetOrderNumber: PropTypes.func,
  profileData: PropTypes.object,
};

export default RepairCheckout;
