import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { OrderBuilderStatus } from "../../store/constants";
import Loading from "../../../../components/Loading/Loading";
import { get} from "lodash";
import { Row, Col, Button, Form } from "react-bootstrap";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EarPiecesFamilyModel from "../EarPiecesFamilyModel/EarPiecesFamilyModel";
import RepairFamilyModel from "../RepairFamilyModel/RepairFamilyModel";
// import { selectClasses } from "@mui/material";

import(
  /* webpackChunkName: "order-builder-ite" */ "./repairItemBuilder.scss"
);

const RepairItemBuilder = ({
  cartData,
  productsData,
  addOrderToCart,
  orderBuilderData,
  orderBuilderStatus,
  loadOrderBuilder,
}) => {
  let history = useHistory();
  const { categoryId, productId, itemId } = useParams();
  const initialProductCategory = productId;
  const initialModelCode = itemId;
  //State used on this page. Split into groups of components
  const [rightSelectedModel, setRightSelectedModel] = useState({});
  const [leftSelectedModel, setLeftSelectedModel] = useState({});
  const [rightSelected, setRightSelected] = useState(false);
  const [leftSelected, setLeftSelected] = useState(false);

  const [selectedModel, setSelectedModel] = useState(false);
  

  const [fitRetention, setFitRetention] = useState(false);
  const [fitTight, setFitTight] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [difficultyWithInsertion, setDifficultyWithInsertion] = useState(false);
  const [cosmeticReasons, setCosmeticReasons] = useState(false);
  const [broken, setBroken] = useState(false);

  const [currentWearer, setCurrentWearer] = useState(false);

  const [enable, setEnable] = useState(false);
  const [veteransAffairs, setVeteransAffairs] = useState(false);
  const [insurance, setInsurance] = useState(false);

  const [enableCheckoutButton, setEnableCheckoutButton] = useState(false);

  useEffect(() => {
    setEnableCheckoutButton(false);
    // console.log("rightSelectedModel, leftSelectedModel", rightSelectedModel, leftSelectedModel);
    if ((rightSelectedModel && rightSelectedModel != "Unknown") || (leftSelectedModel && leftSelectedModel != "Unknown")) {
      setEnableCheckoutButton(true);
    }
  }, [rightSelectedModel, leftSelectedModel]);

  const goToCart = () => {
    if (enableCheckoutButton) {
      // var ;
      const {
        rightEar,
        leftEar,
        earmouldShellRight,
        earmouldShellLeft,
        Accessories,
        Roger,
        ChargerOptions,
        ReasonForRepair,
  	    AdditionalInformation,
      } = document.forms[0];
      // Create Object to send to order confirmation page
      let rightModelCode = "";
      let leftModelCode = "";
      if(rightSelectedModel != "Unknown") {
        rightModelCode = get(rightSelectedModel, "ModelCode", "");
      }
      if(leftSelectedModel != "Unknown") {
        leftModelCode = get(leftSelectedModel, "ModelCode", "");
      }


      var repairRemakeFormObject = {
        // RightModel: rightSelectedModel,
        // RightModelCode: rightModelCode,
        // LeftModel: leftSelectedModel,
        // LeftModelCode: leftModelCode,
        RightModel: rightEar.value,
        RightModelCode: rightEar.value,
        LeftModel: leftEar.value,
        LeftModelCode: leftEar.value,
        // LeftHearingInstruments: leftSelected,
        // RightHearingInstruments: rightSelected,
        LeftEarmouldShells: earmouldShellLeft.value,
        RightEarmouldShells: earmouldShellRight.value,
        AccessoriesCount: Accessories.value,
        RogerCount: Roger.value,
        ChargerOptionsCount: ChargerOptions.value,
        ReasonForRepair: ReasonForRepair.value,
        ReasonForReshellOrRemake: {
          FitRetention: fitRetention,
          FitTight: fitTight,
          Feedback: feedback,
          DifficultyWithInsertion: difficultyWithInsertion,
          CosmeticReasons: cosmeticReasons,
          Broken: broken,
        },
        IsCurrentPhonakHearingAidWearer: currentWearer,
        AdditionalInformation: AdditionalInformation.value,
        IsEnable: enable,
        IsVeteransAffairsClient: veteransAffairs,
        IsInsurance: insurance,
      }

      // console.log("repairRemakeFormObject", repairRemakeFormObject)
      history.push({ 
        pathname: "/Repair/Repair/checkout",
        state: { repairRemakeFormObject }
      });
    }
  };

  return (
    <div className="order-builder-main-div repair-main-div">
      <h1>Repair / Remake</h1>
      <div>
        <div>
          {/* <RepairFamilyModel
            productsData={productsData}
            rightSelectedModel={rightSelectedModel}
            leftSelectedModel={leftSelectedModel}
            setRightSelectedModel={setRightSelectedModel}
            setLeftSelectedModel={setLeftSelectedModel}
            initialProductCategory={initialProductCategory}
            initialModelCode={initialModelCode}
          /> */}
          {/* <EarPiecesFamilyModel
            productsData={productsData}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            initialProductCategory={initialProductCategory}
            initialModelCode={initialModelCode}
          /> */}
          <form onSubmit={goToCart}>

            <table className="w-100 mt-5">
              <tbody>
                <tr>
                  <th>Hearing Aid Model:</th>
                  <td>
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="RightEar">Right Ear</label>
                        <Form.Control
                          type="text"
                          name="rightEar"
                          className="me-2"
                          aria-label="rightEar"
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="LeftEar">Left Ear</label>
                        <Form.Control
                          type="text"
                          name="leftEar"
                          className="me-2"
                          aria-label="leftEar"
                        />
                      </div>
                      {/* <div className="col-6">
                        <input type="checkbox" id="LeftEar" value={leftSelected} onChange={e => setLeftSelected(!leftSelected)} />
                        <label htmlFor="LeftEar">Left Ear</label>
                      </div> */}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Custom Earmould/Shell S/N:</th>
                  <td>
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="earmouldShellRight">Right Ear</label>
                        <Form.Control
                          type="text"
                          name="earmouldShellRight"
                          className="me-2"
                          aria-label="earmouldShellRight"
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="earmouldShellLeft">Left Ear</label>
                        <Form.Control
                          type="text"
                          name="earmouldShellLeft"
                          className="me-2"
                          aria-label="earmouldShellLeft"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Accessory item count</th>
                  <td>
                    <Form.Control
                      type="number"
                      name="Accessories"
                      className="me-2 number-input"
                      aria-label="Accessories"
                      min="1"
                      max="5"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Roger item count</th>
                  <td>
                    <Form.Control
                      type="number"
                      name="Roger"
                      className="me-2 number-input"
                      aria-label="Roger"
                      min="1"
                      max="5"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Charger item count</th>
                  <td>
                    <Form.Control
                      type="number"
                      name="ChargerOptions"
                      className="me-2 number-input"
                      aria-label="ChargerOptions"
                      min="1"
                      max="5"
                    />
                  </td>
                </tr>
                <tr>
                  <h2>Repair</h2>
                </tr>
                <tr>
                  <th>Reason for repair</th>
                  <td>
                    <textarea
                      id="ReasonForRepair"
                      name="ReasonForRepair"
                      rows="8"
                      cols="100"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <h2>Reshell or Remake</h2>
                </tr>
                <tr>
                  <th>Reason for Reshell or Remake</th>
                  <td>
                    <div className="row">
                      <div className="col-6">
                        <div className="row reshell-or-remake-checkboxes">
                          <div className="col-12">
                            <input type="checkbox" id="FitRetention" checked={fitRetention} onChange={e => setFitRetention(!fitRetention)} />
                            <label htmlFor="FitRetention">Fit - Retention</label>
                          </div>
                          <div className="col-12">
                            <input type="checkbox" id="FitTight" checked={fitTight} onChange={e => setFitTight(!fitTight)} />
                            <label htmlFor="FitTight">Fit - Tight</label>
                          </div>
                          <div className="col-12">
                            <input type="checkbox" id="Feedback" checked={feedback} onChange={e => setFeedback(!feedback)} />
                            <label htmlFor="Feedback">Feedback</label>
                          </div>
                          <div className="col-12">
                            <input type="checkbox" id="DifficultyWithInsertion " checked={difficultyWithInsertion} onChange={e => setDifficultyWithInsertion(!difficultyWithInsertion)} />
                            <label htmlFor="DifficultyWithInsertion">Difficulty with Insertion</label>
                          </div>
                          <div className="col-12">
                            <input type="checkbox" id="CosmeticReasons" checked={cosmeticReasons} onChange={e => setCosmeticReasons(!cosmeticReasons)} />
                            <label htmlFor="CosmeticReasons">Cosmetic Reasons</label>
                          </div>
                          <div className="col-12">
                            <input type="checkbox" id="Broken" checked={broken} onChange={e => setBroken(!broken)} />
                            <label htmlFor="Broken">Broken</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 reshell-or-remake-checkboxes">
                        <input type="checkbox" id="CurrentWearer" checked={currentWearer} onChange={e => setCurrentWearer(!currentWearer)} />
                        <label htmlFor="CurrentWearer">Current Phonak Hearing aid Wearer?</label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Additional Information</th>
                  <td>
                    <textarea
                      id="AdditionalInformation"
                      name="AdditionalInformation"
                      rows="8"
                      cols="100"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <th>Quote required for Specific funding type:</th>
                  <td>
                    <div className="row reshell-or-remake-checkboxes">
                      <div className="col-12">
                        <input type="checkbox" id="Enable" checked={enable} onChange={e => setEnable(!enable)} />
                        <label htmlFor="Enable">{`Enable (H.Aid is 5+ years old) - Please quote if the repair cost is more than $235 per H.Aid`}</label>
                      </div>
                      <div className="col-12">
                        <input type="checkbox" id="VeteransAffairs" checked={veteransAffairs} onChange={e => setVeteransAffairs(!veteransAffairs)} />
                        <label htmlFor="VeteransAffairs">{`Veterans Affairs (H.Aid is 5+ years old) - Please qoute if the repair cost is more than $277 per H.Aid`}</label>
                      </div>
                      <div className="col-12">
                        <input type="checkbox" id="Insurance" checked={insurance} onChange={e => setInsurance(!insurance)} />
                        <label htmlFor="Insurance">Insurance - Please provide a quote before any repair is carried out</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row className="go-to-cart-button-button-div mt-5">
              <Col xs={12}>
                <div className="go-to-cart-button-div">
                  <Button type="submit" className={`float-right ${enableCheckoutButton ? "" : "disabled"}`} disabled={!enableCheckoutButton}>
                    Checkout
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RepairItemBuilder;