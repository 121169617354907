import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { head } from "lodash";
import(
  /* webpackChunkName: "order-builder-ite-power-level" */ "./itePowerLevel.scss"
);
import InsertLink from "@material-ui/icons/InsertLink";
import LinkOff from "@material-ui/icons/LinkOff";
import testModel from "../../../../assets/img/ModelTest.png";

const ITEPowerLevel = ({
  setITEPowerLevelForOrder,
  itePowerLevelRight,
  itePowerLevelLeft,
  activeRightITEPowerLevel,
  setActiveRightITEPowerLevel,
  activeLeftITEPowerLevel,
  setActiveLeftITEPowerLevel,
  leftSelected,
  rightSelected,
  iteModelLinked
}) => {
  const [linked, setLinked] = useState(true);

  useEffect(() => {
    //Set initial active powerlevel using head method from lodash.
    // Grabs first item in an array but wont throw an error if the array is empty. Better than array[0]
    if (itePowerLevelRight) {
      const initalActive = head(itePowerLevelRight, {});
      setRightActive(initalActive);
    }
    if (itePowerLevelLeft) {
      const initalActive = head(itePowerLevelLeft, {});
      setLeftActive(initalActive);
    }
  }, [itePowerLevelRight, itePowerLevelLeft]);

  useEffect(() => {
    if (activeRightITEPowerLevel || activeLeftITEPowerLevel) {
      const powerLevels = {
        right: activeRightITEPowerLevel,
        left: activeLeftITEPowerLevel,
      };
      // setITEPowerLevelForOrder(powerLevels);
    }
  }, [activeRightITEPowerLevel, activeLeftITEPowerLevel]);

  
  // Set power levels for order
  // Set other side if still linked
  const setRightActive = (powerLevel) => {
    setActiveRightITEPowerLevel(powerLevel);
    if (linked && iteModelLinked) {
      setActiveLeftITEPowerLevel(powerLevel);
    }
  };

  const setLeftActive = (powerLevel) => {
    setActiveLeftITEPowerLevel(powerLevel);
    if (linked && iteModelLinked) {
      setActiveRightITEPowerLevel(powerLevel);
    }
  };

  return (
    <div>
      {(itePowerLevelRight || itePowerLevelLeft) &&
        (rightSelected || leftSelected) && (
          <div className="ite-power-level-component-main-div order-builder-component">
            <Row className="order-builder-component-title">
              <Col xs={4}>
                <h2>
                  Choose a power level
                </h2>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col md={5}>
                    {rightSelected && (
                      <div className="text-right">
                        <h3>Right Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={2}>
                    {rightSelected && leftSelected && (
                      <div className="text-center link-div">
                        {linked && iteModelLinked ? (
                          <InsertLink
                            className="linked"
                            onClick={() => setLinked(false)}
                          />
                        ) : (
                          <LinkOff
                            onClick={() => setLinked(!linked)}
                            className="unlinked"
                          />
                        )}
                      </div>
                    )}
                  </Col>
                  <Col md={5}>
                    {leftSelected && (
                      <div>
                        <h3>Left Ear</h3>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    {rightSelected && (
                      <Row className="ite-power-level-row-right">
                        {itePowerLevelRight && itePowerLevelRight.map((powerLevel, index) => (
                          <Col key={index} xs={4}>
                            <a
                              onClick={() => {
                                setRightActive(powerLevel);
                              }}
                            >
                              <div
                                className={`ite-power-level-box ${
                                  activeRightITEPowerLevel.Code ===
                                  powerLevel.Code
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <div className="ite-power-level-box-text">
                                  <p>{powerLevel.Name}</p>
                                </div>
                              </div>
                            </a>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Col>
                  <Col md={6}>
                    {leftSelected && (
                      <Row className="ite-power-level-row-left left-side">
                        {itePowerLevelLeft && itePowerLevelLeft.map((powerLevel, index) => (
                          <Col key={index} xs={4}>
                            <a
                              onClick={() => {
                                setLeftActive(powerLevel);
                              }}
                            >
                              <div
                                className={`ite-power-level-box ${
                                  activeLeftITEPowerLevel && activeLeftITEPowerLevel.Code ===
                                  powerLevel.Code
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <div className="ite-power-level-box-text">
                                  <p>{powerLevel.Name}</p>
                                </div>
                              </div>
                            </a>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
    </div>
  );
};

export default ITEPowerLevel;
