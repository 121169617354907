import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

import(/* webpackChunkName: "footer" */ "./footer.scss");

export default function UFooter() {

  return (
    <div className="footer-main-div notprintable">
      <Container>
        <Row className="footer-row">
          <Col md={6}>
            Unitron - A Sonova brand
          </Col>
          <Col md={6}>
            <div className='float-right footer-links'>
            <a href='mailto:nzorders@unitron.com'>Contact</a> | <Link to='/privacy-policy'>Privacy Policy</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
