import { createSelector } from 'reselect';

const products = state => state.modules.storeHome;

export const getProductsStatus = createSelector(
  products,
  state => state.productsStatus
);

export const getProductsData = createSelector(
  products,
  state => state.productsData
);

// getIsStockOrder is actually ORDERSTATUS (see contacts.js)
// 
// 
export const getIsStockOrder = createSelector(
  products,
  state => state.isStockOrder
);
